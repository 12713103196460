import { useEffect, React } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dex from "./components/Dex";
import Statement from "./components/Statement";
import Tokenomics from "./components/Tokenomics";
import HowToBuy from "./components/HowToBuy";
import MemeTool from "./components/MemeTool";
import PFP from "./components/PFP";
import { PriceHolderProvider } from "./components/PriceHolderContext";
import { Helmet } from "react-helmet";
import "./styles/App.css";

const ScrollToSection = () => {
	const { sectionId } = useParams();

	useEffect(() => {
		if (sectionId) {
			const element = document.getElementById(sectionId);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [sectionId]);

	return null;
};

function App() {
	return (
		<DndProvider backend={HTML5Backend}>
			<Router>
				<PriceHolderProvider>
					<div className="App">
						<Helmet>
							<title>$KING | The world will wear the crown</title>
							<meta name="description" content="$KING" />
							<meta
								name="keywords"
								content="$KING, KING, Meme, SOL, king of sol, kingofsol.com, sol king, meme, jesters out kings in, jester, Solana Memes, Memecoin, meme coin, Solana, crypto, King of Sol, blockchain"
							/>
							<link rel="canonical" href="https://www.kingofsol.com" />
							<script type="application/ld+json">
								{`
									{
										"@context": "https://schema.org",
										"@type": "Organization",
										"name": "$KING King of Sol",
										"url": "https://www.kingofsol.com",
										"sameAs": [
										"https://twitter.com/kingsolmeme",
										"https://t.me/WelcometoKING",
										"https://tiktok.com/@kingsolmeme"
										]
									}
									`}
							</script>
						</Helmet>
						<section id="home">
							<Header />
						</section>
						<div className="info-container">
							<section id="links">
								<Dex />
							</section>
							<section id="howtobuy">
								<HowToBuy />
							</section>
							<section id="tokenomics">
								<Tokenomics />
							</section>
							<section id="statement">
								<Statement />
							</section>
						</div>
						<section id="memetool">
							<MemeTool />
						</section>
						<section id="">
							<PFP />
						</section>
						<Footer />
					</div>
					<Routes>
						<Route path="/:sectionId" element={<ScrollToSection />} />
					</Routes>
				</PriceHolderProvider>
			</Router>
		</DndProvider>
	);
}

export default App;
