import React, { useState } from 'react';
import { removeBackground } from '@imgly/background-removal';
import '../styles/PFP.css'; // We'll style the component separately

const PFP = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [crownImage, setCrownImage] = useState(null); // Crown image selected by the user
  const [loading, setLoading] = useState(false);

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file); // Save the file directly
    }
  };

// Handle background removal and add yellow background
const handleBackgroundRemoval = async () => {
    if (!uploadedImage) return;
  
    setLoading(true);
  
    try {
      // Convert file to blob or URL if necessary
      const reader = new FileReader();
  
      reader.onload = async () => {
        const result = reader.result;
  
        try {
          const imageWithRemovedBackground = await removeBackground(result); // This function should handle Base64 data or blob
  
          // Check if the result is a blob and convert it to a URL if necessary
          let imgSrc;
          if (imageWithRemovedBackground instanceof Blob) {
            imgSrc = URL.createObjectURL(imageWithRemovedBackground); // Convert blob to URL
          } else {
            imgSrc = imageWithRemovedBackground; // If it's already a Base64 string, use it directly
          }
  
          // Create a canvas to overlay the yellow background
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const img = new Image();
  
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
  
            // Fill canvas with yellow background
            ctx.fillStyle = '#ffe900';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
  
            // Draw the image with the removed background on top of the yellow background
            ctx.drawImage(img, 0, 0);
  
            setProcessedImage(canvas.toDataURL()); // Convert the canvas to a Base64 image
            setLoading(false);
  
            // Clean up the object URL if we created one
            if (imageWithRemovedBackground instanceof Blob) {
              URL.revokeObjectURL(imgSrc); // Clean up the blob URL to avoid memory leaks
            }
          };
  
          img.src = imgSrc; // Set the image source to the processed image
        } catch (error) {
          console.error('Error removing background:', error);
          setLoading(false);
        }
      };
  
      reader.readAsDataURL(uploadedImage); // Read the uploaded image as Data URL (Base64)
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };
  

  // Handle crown selection
  const handleCrownSelection = (crown) => {
    setCrownImage(crown);
  };

  return (
    <div className="profile-editor">
      <h2>$KING Profile Editor</h2>

      <input type="file" accept="image/*" onChange={handleImageUpload} />

      {uploadedImage && (
        <div>
          <button onClick={handleBackgroundRemoval} disabled={loading}>
            {loading ? 'Processing...' : 'Remove Background and Add Yellow Background'}
          </button>
        </div>
      )}

      {processedImage && (
        <div className="image-preview">
          <img src={processedImage} alt="Processed Profile" />

          {/* Crown selection buttons */}
          <div className="crown-selector">
            <h3>Select a Crown:</h3>
            <img
              src="/assets/crown1.png"
              alt="Crown 1"
              onClick={() => handleCrownSelection('/assets/crown1.png')}
            />
            <img
              src="/assets/crown2.png"
              alt="Crown 2"
              onClick={() => handleCrownSelection('/assets/crown2.png')}
            />
            <img
              src="/assets/crown3.png"
              alt="Crown 3"
              onClick={() => handleCrownSelection('/assets/crown3.png')}
            />
          </div>

          {crownImage && (
            <div className="image-with-crown">
              <img src={processedImage} alt="Profile with Crown" className="base-image" />
              <img src={crownImage} alt="Crown" className="crown-overlay" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PFP;
